<template>
  <div>
    <title-nav :title="'이벤트관리'" :nav="'CS / 이벤트관리'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col></b-col>
            <b-col cols="5">
              <b-input-group size="sm">
                <b-form-select
                  slot="prepend"
                  type="search"
                  v-model="searchType"
                  :options="searchOptions"
                  size="sm"
                  class="mr-3"
                ></b-form-select>
                <b-form-input
                  v-model="searchText"
                  placeholder="Search"
                  @keyup.enter="init"
                ></b-form-input>
                <b-input-group-append is-text size="sm">
                  <b-icon icon="search" @click="init"></b-icon>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <template v-slot:cell(user_sn)="data">
              <router-link
                :to="{ name: 'detail', params: { usersn: data.value } }"
                variant="link"
                >{{ data.value }}</router-link
              >
            </template>

            <template v-slot:cell(reg_dt)="data">
              <span>{{ data.value | dateFilter }}</span>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="curPage"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { event_list } from '@/api/cs'

export default {
  name: 'Event',
  data() {
    return {
      curPage: 1,
      rowPerPage: 20,
      records: 0,
      isBusy: false,

      fields: [
        {
          key: 'user_sn',
          label: '고객 번호'
        },
        {
          key: 'email',
          label: '이메일'
        },
        {
          key: 'user_name',
          label: '닉네임'
        },
        {
          key: 'real_name',
          label: '이름'
        },
        {
          key: 'hpno',
          label: '휴대전화번호'
        },
        {
          key: 'event_type',
          label: '구분'
        },
        {
          key: 'reg_dt',
          label: '신청일'
        }
      ],
      items: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      searchType: '',
      searchText: '',
      searchOptions: [
        { value: '', text: '선택' },
        { value: 'user_sn', text: '고객번호' },
        { value: 'nick', text: '닉네임' },
        { value: 'email', text: '이메일' }
      ]
    }
  },
  watch: {
    rowPerPage: function(newValue) {
      console.log(newValue)
      this.init()
    }
  },
  methods: {
    async init() {
      this.isBusy = true
      const resp = await event_list(this.curPage, this.rowPerPage, this.searchType, this.searchText)
      if (resp.code === '0000') {
        this.items = resp.data.list
      }
      this.records = resp.data.totCnt
      this.isBusy = false
    },
    setPage(page) {
      this.curPage = page
      this.init()
    }
  },
  mounted() {
    this.init()
  }
}
</script>
